* {
  font-family: 'Poppins', sans-serif;
  outline: 'none'; }
// TODO: Remove this file
body {
  margin: 0;
  padding: 0; }

html, body, #root {
  height: 100%; }

@mixin dFlex {
  display: flex;
  align-items: center; }

.app {
  padding: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column; }

.app__header {
  @include dFlex;
  justify-content: space-between;
  margin: 20px 15px;
  min-height: 57px; }


.app__header-right {
  display: flex;
  align-items: flex-end; }

.app__home {
  text-align: left;

  a {
    text-transform: capitalize; } }

.select__location {
  width: 300px;
  margin-right: 25px !important; }

.app_sidebar {
  width: 250px;
  height: 100vh;
  display: flex;
  flex-direction: column; }

.app__footer {
  margin-top: auto;
  font-size: 12px;
  text-align: center; }

.parameter__value {
  margin: 0;
  @include dFlex; }

// FIXME: Move this all to Live.js
.parameter {
  cursor: pointer;
  font-size: 1rem;
  opacity: 0.3;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    transition: opacity 0.2s ease-in-out;
    opacity: 0.6;
 }    // background: red

  &--active {
    font-size: 1rem;
    opacity: 1;

    &:hover {
      opacity: 1; } } }

.duration__button {
  width: 50px; }

#line-chart-canvas canvas {
  // For showing unit properly
  padding-left: 5px;
  width: calc(100% - 64px) !important;
  height: 100% !important; }

@media (max-width: 959px) {
  #line-chart-canvas canvas {
    max-height: 80vh;
    width: 100% !important; } }

@media (max-width: 640px) {
  .app__header {
    flex-direction: column;
    margin: 0px;
    padding:  0; }

  .app__header-right {
    width: 95%;
    flex-direction: column; }

  .parameter--active {
    box-shadow: 0px 0px 8px 3px #c3c3c35c; }

  #line-chart-canvas canvas {
    padding: 0;
    width: 100% !important;
    max-height: 80vh; }

  .duration__button {
    padding: 0 !important;
    min-width: 30px !important;
    width: 30px; }

  .settings > label {
    width: 100%; } }



@keyframes topToBottom {
	0% {
        opacity: 0; }
	5% {
        opacity: 0; transform: translateY(-16px); }
	10% {
        opacity: 1; transform: translateY(0px); }
	25% {
        opacity: 1; transform: translateY(0px); }
	30% {
        opacity: 0; transform: translateY(16px); }
	80% {
        opacity: 0; }
	100% {
        opacity: 0; } }


.banner-text__moving-text {
  text-indent: 8px;

  > span {
    animation: topToBottom 15s linear infinite 0s;
    color: white;
    opacity: 0;
    overflow: hidden;
    position: absolute; }


  > span:nth-child(2) {
    animation-delay: 2.5s; }

  > span:nth-child(3) {
    animation-delay: 5s; }

  > span:nth-child(4) {
    animation-delay: 7.5s; }

  > span:nth-child(5) {
    animation-delay: 10s; }

  > span:nth-child(6) {
    animation-delay: 12.5s; } }

.banner-text__link {
  display: flex;
  margin-top: 2rem;

  &--link {
    margin-left: 0.2em;
    font-weight: bold; } }

.banner-image {
  width: 50%; }

.Toastify__toast--info {
  background: #1a61fb; }

.Toastify__toast {
  font-size: 14px; }

@media (max-width: 2000) {
  .banner {
    flex-direction: column-reverse; }

  .banner-text, .banner-image {
    width: auto; } }
