@media (max-width: 960px) {
    .snackbar {
        .MuiSnackbarContent {
            &-root {
                flex-grow: inherit;
                background: rgba(49, 49, 49, 0.85); }

            &-message {
                font-size: 0.78rem;
                text-align: center;
                padding: 2px 0; }

            &-action {
                display: none; } } } }
